import React from 'react'
import * as UI from '@/components/UI'
import { Section, Grid, TextCell, RichText } from './Content.css'

const ContentTerms = ({ content }) => {
  const html = content?.content_terms?.html

  if (!html) return null

  return (
    <Section>
      <UI.Container>
        <Grid>
          <TextCell>
            <UI.SectionTitle>{content.main_title}</UI.SectionTitle>
          </TextCell>
          <TextCell>
            <RichText dangerouslySetInnerHTML={{ __html: html }} />
          </TextCell>
        </Grid>
      </UI.Container>
    </Section>
  )
}

export default ContentTerms
