import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  margin: 1.5rem 0rem 4rem;

  ${above('md')`
    margin: 4rem 4rem 8.5rem;
  `}
  ${above('lg')`
    margin: 6.25rem 12rem 10.25rem;
  `}
`

export const Grid = styled(UI.Grid)`
  justify-content: center;
  align-items: stretch;
`

export const TextCell = styled.div`
  width: ${getColumnSize(12)};
  margin-bottom: 4rem;
  ${above('md')`
    margin-top: 4rem;
    margin-bottom: 5rem;
    width: ${getColumnSize(6)};
  `}
`

export const RichText = styled.div`
  font-size: 1rem;
  line-height: 1.875rem;

  color: ${p => p.theme.colors.maastricht};
  ul,
  ol {
    margin: 1rem 0 2rem;
    list-style: disc;
  }
  p + p {
    opacity: 0.8;
    margin-top: 1rem;
  }
  ol {
    opacity: 0.8;
    list-style: decimal-leading-zero;
  }
  li {
    list-style-position: inside;
  }
  h1,
  h2,
  h3 {
     ${getTypeStyle('caption')}
    ${above('md')`
      ${getTypeStyle('subtitle')}
    `}
    color: ${p => p.theme.colors.maastricht};
    font-weight: 500;
    font-family: ${p => p.theme.type.fonts.headline};
    margin: 1rem 0 2rem;
  }
  a {
    color: ${p => p.theme.colors.blue};
    text-decoration: underline;
  }
`
